import * as React from "react"
import { Head } from "../components/head"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Service } from "../components/_services/service"
import { useStaticQuery, graphql } from "gatsby"
import { serviceText } from "../content/servicesContent"
import { Header } from "../components/shared/type"
import { breakPoints } from "../components/shared/break-points"

const WhatWeDoPage = (): JSX.Element => {
  const {
    marketingSEOImg,
    webDevImg,
    brandingImg,
    socialMediaImg,
  } = useStaticQuery(
    graphql`
      query {
        webDevImg: file(relativePath: { eq: "services/webDev.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        marketingSEOImg: file(
          relativePath: { eq: "services/marketingSEO.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        brandingImg: file(relativePath: { eq: "services/branding.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialMediaImg: file(relativePath: { eq: "services/socialMedia.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const webDevFluid = webDevImg.childImageSharp.fluid
  const marketingSEOFluid = marketingSEOImg.childImageSharp.fluid
  const brandingFluid = brandingImg.childImageSharp.fluid
  const socialMediaFluid = socialMediaImg.childImageSharp.fluid

  return (
    <>
      <SEO title="What We Do" />
      <Head title={"What We Do"} />
      <Layout>
        <Container>
          <Service
            fluid={webDevFluid}
            descriptions={serviceText.webdev}
            link="/web-design-and-development"
            flip={false}
          >
            <Title>
              Web Design & <SBR />
              Development
            </Title>
          </Service>

          <Service
            fluid={marketingSEOFluid}
            descriptions={serviceText.digitalMarketing}
            link="/digital-marketing-and-seo"
            flip={true}
          >
            <Title>
              Digital Marketing
              <SBR /> & SEO
            </Title>
          </Service>

          <Service
            fluid={brandingFluid}
            descriptions={serviceText.brand}
            link="/brand-development"
            flip={false}
          >
            <BrandingTitle >
              Brand <SBR /> Development
            </BrandingTitle>
          </Service>

          <Service
            fluid={socialMediaFluid}
            descriptions={serviceText.social}
            link="/social-media-and-content-creation"
            flip={true}
          >
            <Title>
              Social Media &<br />
              Content Creation
            </Title>
          </Service>
        </Container>
      </Layout>
    </>
  )
}


const Container = styled.div`
  grid-column: 1/-1;
  @media (min-width: 768px) {
    *:nth-child(1) > #fluid {
      height: 300px;
    }

    *:nth-child(2) > #fluid {
      height: 450px;
    }

    *:nth-child(3) > #fluid {
      height: 330px;
    }

    *:nth-child(4) > #fluid {
      height: 500px;
    }
  }
`

const Title = styled(Header)`
  text-align: center;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1.25rem;
  }
`

const BrandingTitle = styled(Title)`
  margin-top: -45px;
  @media(min-width: ${breakPoints.tablet}){
    margin-top: 0;
  }
`

const SBR = styled.br`
  @media (min-width: 768px) {
    display: none;
  }
`

export default WhatWeDoPage
