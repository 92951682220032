import * as React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import { Button } from "../../atoms/button"

interface Props {
  children: any
  fluid: any | false
  descriptions: string[]
  link: string
  flip: boolean
}

export const Service: React.FC<Props> = props => {
  const { descriptions, link, fluid, flip } = props
  if (!descriptions) return null

  const Container = styled.div`
    padding: var(--section-padding-mobile);
    display: grid;
    background-color: ${flip ? "var(--grey)" : "var(--white)"};
    
    @media (min-width: 768px) {
      padding: var(--section-padding-desktop);
      grid-template-columns: 1fr 1fr;
      padding: var(--section-padding-desktop);
      justify-content: center;
    }
  `

  const FluidImg = styled(BackgroundImage)`
    grid-column: 1/-1;
    width: auto;
    height: 250px;
    margin: 0 50px;

    @media (min-width: 768px) {
      height: 500px;
      margin: 0;
      padding: 0;
      grid-column: initial;
      order: ${flip ? "initial" : "1"};
      align-self: center;

      /* the sizing of these images is handled in what-we-do container */
    }
  `
  return (
    <Container>
      {fluid && (
        <FluidImg
          id="fluid"
          fluid={fluid}
          style={{ backgroundSize: "contain", marginBottom: "1.2rem" }}
        />
      )}
      <ContentWithAction>
        {props.children}
        {descriptions.map(desc => (
          <Description key={desc}>{desc}</Description>
        ))}
        <Link to={"/packages/" + link} style={{display:"block", marginTop: "2rem"}}>
          <StartBtn>get started</StartBtn>
        </Link>
      </ContentWithAction>
    </Container>
  )
}

const ContentWithAction = styled.div`
  margin: 0 32px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const Description = styled.p`
  @media (min-width: 768px) {

  }
`

const StartBtn = styled(Button)`
  margin: 0 auto;
  width: 80%;

  @media (min-width: 768px) {
    width: 270px;
    padding: 1.4rem;
  }
`
