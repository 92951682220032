export const serviceText = {
  webdev: [
    "Whether you are looking for a site refresh, a full brand identity, or just needing to outsource your web needs, our skilled team of designers and developers are here to help. Take advantage of one of our fully responsive custom web design packages, or work with our team to develop just about anything you can dream up.",
  ],
  webMarketing: [
    "How often your site is updated, and how fresh your content or products are is crucial to your business and ROI. Adding new products, sales and promos to your e-commerce site, updating your blog or porfolio and keeping your site up-to-date in every aspect of your business drives traffic, sales and increases your online presence. Our web add-ons include monthly marketign and maintence packages for every type and size of business.",
  ],
  digitalMarketing: [
    "With 95% of web traffic going through the first page of Google web searches, Search Engine Optimization (SEO) has become increasingly vital to traffic being driven to your page. Who Studio’s web developers are skilled in optimizing page structure and content to boost your site and place you higher in relevant searches; while our marketing team specializes in strategic digital marketing & web campaigns to help spread the word of your newest promotions.",
    "The current digital media field offers greater control and return on advertising dollars than ever before. Both Facebook and Instagram’s paid advertising platforms have become a valuable marketing resource. Statistics show that 94% of social media marketers use the Facebook ads platform, a logical strategy considering a staggering 69% of all adults use Facebook, and 51% of millennials say they can’t go 5 hours without checking their social media.",
    "Take a look at our Marketing packages to get started with one of our Digital marketing Specialists today!",
  ],
  brand: [
    "Got a great idea but don’t know where to start? Have a concrete vision and need a team to help execute? Brand identity is crucial to brand loyalty, and presenting a brand consistently across all platforms can increase revenue by up to 23%. Our designers and marketing professionals love to get creative and help bring your business to life.",
  ],
  social: [
    "It is now more important than ever for brands to have a strong online presence. Social Media has become not only a crucial part of that, but for many businesses their greatest driver of revenue. With over a third of Instagram users having purchased a product through social media on their mobile phone, we're seeing an increase in Ecommerce potential.",
    "With 1 billion users every month, and 60% of those users seeking out and discovering new products directly through the platform, strong social strategy and presence can have a unprecedented impact on your revenue.",
    "The Who Studio team specializes in content creation, social media management, and marketing strategies to help increase your audience and drive sales.",
  ],
}
